/* Profile Page Container */
.profile-content {

}

/* Profile Page Content Container */
.profile-content-wrapper {

}

/* ProfileBody Container and Components */
.profile-body-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    flex-wrap: wrap;
    width: 100%;
}

.profile-body-header {
    flex: auto;
    display: flex;
    flex-direction: row;
}

.profile-image {
    border: none;
    background-color: transparent;
    width: 50%;
    height: 50%;
    border-radius: 50%;
    margin-right: 50px;
    margin-bottom: 35px;
    align-self: flex-start;
    width: 90px;
    height: 90px;
}

.profile-header-stack {

}

.profile-header-name {
    font-size: 24px;
}

.profile-header-bio {
    font-size: 15px;
}

/* FriendButton Container */
.friend-button-container {
    flex: auto;
    display: flex;
    flex-direction: row;
}

.edit-profile-icon {
    height: 16px;
    width: 16px;
    margin-left: 10px;
}

@media(max-width: 479px) {
    .profile-body-container {
        flex-wrap: nowrap;
        align-items: center;
        height: 100px;
    }
}