.horizontal-bar-inline-text {
    text-align: center;
    color: var(--dl-color-gray-500);
    margin-top: 15px;
    margin-bottom: 5px;
}

    .horizontal-bar-inline-text::before,
    .horizontal-bar-inline-text::after {
        background-color: var(--dl-color-gray-500);
        content: "";
        display: inline-block;
        height: 1px;
        position: relative;
        vertical-align: middle;
        width: 50%;
    }

    .horizontal-bar-inline-text::before {
        right: 0.5em;
        margin-left: -50%;
    }

    .horizontal-bar-inline-text::after {
        left: 0.5em;
        margin-right: -50%;
    }
