.rounded-attribute-container-delete-icon {
    width: 15px;
    height: 15px;
    align-self: center;
    margin-right: var(--dl-space-space-unit);
}

.rounded-attribute-container {
    height: 20px;
    margin: 5px;
    border-radius: 25px;
    width: auto;
    display: flex;
    position: relative;
    align-items: center;
    border: solid;
    border-width: 1px;
}

.rounded-attribute-container-text {
    font-size: 13px;
    align-self: center;
    font-style: normal;
    font-weight: 500;
    margin-top: 18px;
    margin-right: var(--dl-space-space-unit);
    margin-left: var(--dl-space-space-unit);
}
