/* Account Icon */
.account-icon {
    border-radius: 50%;
    width: 40px !important;
    height: 40px !important;
    object-fit: cover;
}

.account-icon-button {
    border: none;
    background-color: transparent;
    margin-left: var(--dl-space-space-twounits);
    width: 50%;
    height: 50%;
}

/* Account Card */
.menu-account-icon {
    border-radius: 50%;
    width: 75px;
    height: 75px;
    object-fit: cover;
}

.account-menu-label {
    width: 90px;
}

.account-menu-body {
    flex: auto;
    display: flex;
    flex-direction: column;
}

.navigation-links-text {
    text-decoration: none;
    color: black;
}

.account-management-nav {
    flex: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20%;
}

.account-menu-style {
    position: absolute;
    width: 150%;
    height: 1500px;
    display: flex;
    justify-content: center;
    align-content: center;
    padding-top: 80px;
    padding-right: 40px;
    align-items: flex-start;
    z-index: 999;
    top: -15%;
    right: -25%;
}

.account-menu-content-style {
    position: relative;
    padding: 32px;
    background-color: #FFF;
    border-radius: 25px;
    z-index: 999;
    border: 1px solid var(--dl-color-gray-700);
    right: inherit;
    top: 20px;
}

.account-menu-user-card {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
}

.account-menu-email {
    color: var(--dl-color-gray-700);
}

/* Account Page / complete info page*/
.account-management-header {
    flex: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.account-management-body {
    display: flex;
    flex-direction: row;
}

.account-management-container {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    align-self: stretch;
    flex-direction: column;
}

.account-management-edit-profile-image-button {
    width: 220px;
}

.account-management-profile-image {
    border: none;
    background-color: transparent;
    border-radius: 50%;
    align-self: flex-start;
    width: 200px;
    height: 200px;
    object-fit: cover
}

.account-management-profile-image-edit-icon-container {
    display: flex;
    justify-content: space-between;
    align-self: stretch;
}

.account-management-profile-image-edit-icon {
    margin-left: 15px;
    top: -3px;
    position: relative;
}

.account-management-name-header {
    margin-top: 50px;
    font-size: 24px;
}

.account-management-property-container {
    flex: auto;
    display: flex;
    flex-direction: column;
}

.account-management-profile-image-container {
    width: 50%;
    height: 50%;
}

.account-management-property-label {
    font-size: 18px;
    margin-top: 10px;
    width: fit-content;
}

.account-management-section-title {
    margin-top: 14px;
}

.account-management-property-input-container {
    flex: auto;
    display: flex;
    flex-direction: row;
}

.account-management-property-input {
    width: 260px;
    height: 35px;
}

.account-management-property-controls-button {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    background-color: unset;
    margin-right: 10px;
    padding: unset;
    padding-block: unset;
    padding-inline: unset;
    width: 40px;
    border: none;
}

    .account-management-property-controls-button:hover {
        background-color: var(--dl-color-gray-700);
    }

.continue-button {
    transition: 0.3s;
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    margin: 20px;
}

    .continue-button:hover {
        color: var(--dl-color-gray-white);
        background-color: var(--dl-color-gray-black);
    }

    .continue-button:disabled {
        color: var(--dl-color-gray-white);
        background-color: var(--dl-color-gray-700);
        border-color: unset;
    }

.error-message-label {
    color: red;
    font-size: 12px;
    max-width: var(--dl-size-size-maxwidth);
}

.complete-account-info-container {
    margin: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.complete-account-info-header {
    font-size: 28px;
    font-weight: 400;
}

.change-profile-image-popup {
    display: flex;
    flex-direction: column;
    height: 100px;
    align-items:center;
    justify-content: space-between;
}


@media(max-width: 993px) {
    .account-icon-button {
        margin-left: 0;
        margin-right: 15px;
    }

}

@media(max-width: 479px) {
    .account-icon-button {
        margin-left: 0;
        margin-right: 15px;
    }

    .account-menu-content-style {
        top: 150px;
        right: -30px;
    }

    .account-management-container {
        padding: 20px;
    }

    .account-management-property-container {
        margin-left: 20px;
    }

    .account-management-section-title {
        margin-top: 30px;
    }

    .account-menu-style {
        width: 100%;
        right: 1px;
    }
}