/* Main Navbar/Header Containers */
.navbar-container {
    flex: 0 0 auto;
    width: 100%;
    height: 100px;
    display: flex;
    position: relative;
    align-items: flex-start;
    justify-content: center;
}

.navbar-header {
    width: 100%;
    display: flex;
    align-items: center;
    /* Not sure if this border is used */
    border-color: var(--dl-color-gray-black);
    border-width: 1px;
    padding-right: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: space-between;
}

/* Navigation Links Containers */
.navbar-container1 {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.navbar-nav {
    display: flex;
    align-items: center;
    padding-left: var(--dl-space-space-twounits);
    flex-direction: row;
}

/* Waitlist and Account Button Container */
.navbar-btn-group {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

/* Mobile Menu Open/Close Buttons */
.navbar-burger-menu {
    display: none;
}

.navbar-icon {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    display: none;
}

.navbar-close-mobile-menu {
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar-icon02 {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
}

/* Mobile Navbar Containers */
.navbar-mobile-menu {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    display: none;
    padding: 32px;
    z-index: 100;
    position: absolute;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
}

.navbar-nav1 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.navbar-container2 {
    width: 90%;
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-threeunits);
    justify-content: space-between;
}

/* Mobile Navbar Social Media Icons */
.navbar-icon04 {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-right: var(--dl-space-space-twounits);
}

.navbar-icon06 {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-right: var(--dl-space-space-twounits);
}

.navbar-icon08 {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
}

/* Media Queries */
@media(max-width: 991px) {
    .navbar-burger-menu {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .navbar-header {
        padding-right: 0;
    }

    .navbar-icon {
        display: flex;
    }
}

@media(max-width: 767px) {
    .navbar-container {
        height: auto;
    }

    .navbar-header {
        padding-left: var(--dl-space-space-twounits);
    }

    .navbar-burger-menu {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .navigation-links-nav{
        align-items:flex-start;
    }
}

@media(max-width: 479px) {
    .navbar-header {
        width: 100%;
        padding: var(--dl-space-space-unit);
    }

    .navbar-mobile-menu {
        display: none;
        padding: 16px;
    }
}
