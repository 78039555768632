.trip-member-control-popup {
    position: absolute;
    width: 150%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    padding-top: 80px;
    padding-right: 40px;
    align-items: flex-start;
    z-index: 999;
    top: -15%;
    right: -25%;
}

.trip-member-control-popup-content {
    padding: 32px;
    background-color: #FFF;
    border-radius: 25px;
    z-index: 999;
    border-color: var(--dl-color-gray-700);
    border-width: 1px;
    border-style: solid;
    align-self: center;
}

.trip-member-list-container {
    display: flex;
    flex-direction: row;
    margin: 5px;
    justify-content: space-between;
}

.trip-member-list-item {
    display: flex;
    flex-direction: row;
}

.trip-member-list-profile-picture {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    object-fit: cover;
    border-radius: 50%;
}

.trip-member-list-profile-name-container {
    display: flex;
    flex-direction: column;
}

.trip-member-list-profile-display-name {
}

.trip-member-list-profile-second-name {
    font-size: 12px;
    color: var(--dl-color-gray-700)
}

.trip-member-list-popup-content {
    display: flex;
    flex-direction: column;
}

.add-trip-member-popup-label {
    margin-bottom: 5px;
}

.add-trip-member-input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.add-trip-member-check-icon {
    margin-left: 5px;
}

.add-trip-member-no-user-found-text {
    font-size: 12px;
    color: var(--dl-color-gray-700);
    overflow-wrap: normal;
    width: 200px;
    margin-bottom: 5px;
}

.add-trip-member-no-user-found-container {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.add-trip-member-no-user-found-invite-button {
    width: 120px;
    height: 30px;
    padding: 1px;
}

.add-trip-member-send-invite-button-icon {
    position: relative;
    top: -3px;
}

.add-trip-member-input-errors {
    font-size: 12px;
    overflow-wrap: normal;
    color: var(--dl-color-danger-700)
}

.member-status-container {
    font-size: 12px;
    height: 30px;
    padding: 5px;
}