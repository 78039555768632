/* Search Bar Container and Components */
.search-bar-container {
    width: 100%;
    align-self: center;
    align-items: center;
    justify-content: center;
}

.search-bar-input {
    width: 600px;
    height: var(--dl-size-size-small);
    font-size: 20px;
    align-self: center;
    box-shadow: 5px 5px 10px 0px #d4d4d4;
    margin-right: var(--dl-space-space-oneandhalfunits);
    border-radius: 25px;
    padding-left: var(--dl-space-space-unit);
}

.search-input-validation-errors {
    color: red;
    font-size: 12px;
    max-width: var(--dl-size-size-maxwidth);
    text-align: center;
}

.search-button {
    transition: 0.3s;
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    margin: 20px;
    border-radius: 10px;
}

    .search-button:hover {
        color: var(--dl-color-gray-white);
        background-color: var(--dl-color-gray-black);
    }

    .search-button:disabled {
        color: var(--dl-color-gray-white);
        background-color: var(--dl-color-gray-700);
        border-color: unset;
    }

@media(max-width: 1500px) {
    .search-bar-input {
        width: 450px;
        margin-right: 0px;
    }
}

@media(max-width: 900px) {
    .search-bar-input {
        width: 350px;
    }
}

@media(max-width: 479px) {
    .search-bar-container {
        display: flex;
        flex-direction: row;
    }

    .search-bar-input {
        width: 300px;
    }
}
