/* Waitlist Popup Containers */
.waitlistPopup-container {
    display: flex;
    overflow: auto;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
}

.waitlistPopup-container1 {
    flex: 0 0 auto;
    display: flex;
    align-self: center;
    align-items: center;
    flex-direction: column;
}

/* Waitlist Main Container */
.waitlist-container1 {
    flex: 0 0 auto;
    height: 100%;
    display: flex;
    align-self: center;
    flex-direction: column;
    justify-content: center;
}

/* Waitlist Components Containers */
.waitlist-container2 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.email-submission-container {
    flex: 0 0 auto;
    height: 100%;
    display: flex;
    align-self: center;
    flex-direction: column;
    justify-content: center;
}

.email-submission-success-container {
    flex: 0 0 auto;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    border: 5px solid black;
    background-color: green;
    border-width: 1px;
    margin-left: var(--dl-space-space-unit);
}

/* Waitlist Components */
.waitlist-text {
    font-size: 0.75rem;
    text-align: center;
    font-weight: 600;
    margin-bottom: var(--dl-space-space-twounits);
    letter-spacing: 2px;
    text-transform: uppercase;
}

.waitlist-text1 {
    font-size: 3rem;
    text-align: center;
    margin-bottom: var(--dl-space-space-unit);
}

.waitlist-text2 {
    max-width: var(--dl-size-size-maxwidth);
    text-align: center;
    margin-bottom: var(--dl-space-space-twounits);
}

.waitlist-button {
    color: var(--dl-color-gray-white);
    font-size: 0.75rem;
    transition: 0.3s;
    margin-left: var(--dl-space-space-unit);
    background-color: var(--dl-color-gray-black);
}

    .waitlist-button:hover {
        color: var(--dl-color-gray-black);
        background-color: transparent;
    }

.email-submission-success {
    color: white;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
}

.check-icon {
    color: green;
}

.email-submission-errors {
    color: red;
    font-size: 12px;
    max-width: var(--dl-size-size-maxwidth);
    text-align: center;
}

/* Unused Class */
.waitlist-textinput {
    font-size: 0.75rem;
    border-color: var(--dl-color-gray-500);
}

/* Media Queries */
@media(max-width: 991px) {
    .waitlist-container {
        justify-content: center;
    }

    .waitlist-container1 {
        width: auto;
    }

    .waitlist-text2 {
        width: 100%;
    }
}

@media(max-width: 767px) {
    .waitlist-container1 {
        width: 100%;
    }

    .waitlist-text1 {
        width: 100%;
    }

    .waitlist-text2 {
        padding-left: var(--dl-space-space-unit);
        padding-right: var(--dl-space-space-unit);
    }
}

@media(max-width: 479px) {

    .waitlist-container2 {
        align-items: center;
        flex-direction: column;
    }

    .waitlist-button {
        margin-top: var(--dl-space-space-unit);
        margin-left: 0px;
    }

    .email-submission-success-container {
        margin-top: var(--dl-space-space-unit);
        margin-left: 0px;
    }
}
