.account-management {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    height: 100%;
    align-items: center;
    flex-direction: column;
}

.account-management-page-body {
    flex: 0 0 auto;
    display: flex;
    align-self: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

@media(max-width: 767px) {
    .account-management-container {
        width: 100%;
    }
}

@media(max-width: 479px) {
    .account-management-container {
        width: 100%;
        height: 100%;
    }
}
