.main-layout-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: stretch;
    flex-direction: column;
}

.main-layout-container1 {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    max-width: 100%;
    max-height: 100%;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.main-layout-container2 {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    padding: var(--dl-space-space-twounits);
    position: relative;
    align-items: stretch;
    justify-content: space-evenly;
}

.main-sidebar-container {
    width: 30%;
}

/*mobile*/
.mobile-sidebar-container {
    position: absolute;
    width: 100%;
    transition: all 0.3s ease-in-out;
    transform: translate(-500px);
    top: 100px;
    background-color: white;
    /* background: red; */
    height: 100vh;
    z-index: 3;
    overflow: hidden;
}

.mobile-sidebar-container-open {
    transform: translate(0) !important;
}

.navbar-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobile-navbar-header {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.mobile-navbar-container {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
}

.mobile-navbar-btn-group {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

@media(max-width: 700px) {
    .mobile-sidebar-container {
        transform: translate(-700px);
    }
}

@media(max-width: 479px) {
    .mobile-sidebar-container {
        transform: translate(-500px);
    }

    .main-layout-container2 {
        padding: 5px;
        justify-content: flex-start;
    }
}