/* Home Page Container */
.home-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    flex-direction: column;
    justify-content: center;
}

/* Home Page Content Container */
.home-container1 {
    flex: 0 0 auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

/* Home Page Hero Containers and Components */
.home-hero {
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-threeunits);
    min-height: 80vh;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.home-container2 {
    display: flex;
    margin-right: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    flex-direction: column;
}

.home-text {
    font-size: 3rem;
    max-width: 450px;
}

.home-text1 {
    margin: var(--dl-space-space-twounits) 0;
}

.home-btn-group {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.home-button {
    transition: 0.3s;
    padding: var(--dl-space-space-unit) var(--dl-space-space-twounits);
}

    .home-button:hover {
        color: var(--dl-color-gray-white);
        background-color: var(--dl-color-gray-black);
    }

.home-button1 {
    transition: 0.3s;
    margin-left: var(--dl-space-space-unit);
    padding: var(--dl-space-space-unit) var(--dl-space-space-twounits);
    border-color: transparent;
}

    .home-button1:hover {
        border-color: var(--dl-color-gray-black);
    }

.home-image {
    width: 400px;
    object-fit: cover;
}

/* Media Queries */
@media(max-width: 991px) {
    .home-hero {
        flex-direction: column;
    }

    .home-container2 {
        align-items: center;
        margin-right: 0px;
        margin-bottom: var(--dl-space-space-twounits);
        padding-right: 0px;
    }

    .home-text {
        text-align: center;
    }

    .home-text1 {
        text-align: center;
        padding: 0 var(--dl-space-space-threeunits);
    }
}

@media(max-width: 767px) {
    .home-hero {
        padding: 0 var(--dl-space-space-twounits);
    }

    .home-text1 {
        padding: 0 var(--dl-space-space-unit);
    }

    .home-image {
        width: 80%;
    }
}

@media(max-width: 479px) {
    .home-container1 {
        width: auto;
    }

    .home-image {
        display: none;
    }

    .home-hero {
        padding: var(--dl-space-space-twounits) var(--dl-space-space-unit);
    }

    .home-container2 {
        margin-bottom: var(--dl-space-space-unit);
    }

    .home-btn-group {
        flex-direction: column;
    }

    .home-button1 {
        margin-top: var(--dl-space-space-unit);
        margin-left: 0px;
    }
}
