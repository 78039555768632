.dashboard-container3 {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
}

.dashboard-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}

.rbc-calendar {
    width: 900px;
}

@media(max-width: 1500px) {
    .rbc-calendar {
        width: 700px;
    }
}

@media(max-width: 1100px) {
    .rbc-calendar {
        width: 500px;
    }
}

@media(max-width: 900px) {
    .dashboard-container3 {
        flex-direction: column;
    }

    .rbc-calendar {
        width: 600px;
    }

    .dashboard-container3 {
        align-items: center;
    }
}

@media(max-width: 900px) {
    .rbc-calendar {
        width: 500px;
    }
}

@media(max-width: 479px) {
    .rbc-calendar {
        width: 420px;
    }

    .dashboard-container3 {
        align-items: flex-start;
    }
}