/* Entire Footer Container */
.footer-footer {
    width: 100%;
    display: flex;
    align-items: center;
    padding: var(--dl-space-space-twounits) var(--dl-space-space-threeunits);
    border: 1px var(--dl-color-gray-black);
    flex-direction: column;
    justify-content: space-between;
}

/* Horizontal Line Separator */
.footer-separator {
    flex: 0 0 auto;
    width: 100%;
    height: 0px;
    display: flex;
    margin: var(--dl-space-space-twounits) 0;
    align-items: flex-start;
    border: 1px solid var(--dl-color-gray-900);
    flex-direction: row;
    border-top-width: 0px;
}

/* Footer Content Containers */
.footer-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}

.footer-container1 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.footer-icon-group {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

/* Social Media Icons */
.footer-icon {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-right: var(--dl-space-space-twounits);
}

/* NavLink to Legal Page */
.legal-nav-button {
    margin-top: 15px;
    text-decoration: none;
    color: black;
}

/* Footer Media Queries */
@media(max-width: 767px) {
    .footer-footer {
        padding: 0 var(--dl-space-space-twounits);
    }

    .footer-separator {
        margin: var(--dl-space-space-oneandhalfunits) 0;
    }

    .footer-container1 {
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
    }

    .footer-text {
        margin-bottom: var(--dl-space-space-oneandhalfunits);
    }

    .footer-icon {
        margin-right: 0;
    }
}

@media(max-width: 479px) {
    .footer-footer {
        padding: var(--dl-space-space-unit);
    }

    .footer-separator {
        margin: var(--dl-space-space-oneandhalfunits) 0;
    }

    .footer-container1 {
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
    }

    .footer-text {
        text-align: center;
        margin-bottom: var(--dl-space-space-oneandhalfunits);
    }
}
