/* Sidebar Container */
.sidebar-submenu-navigate-back {
    display: flex;
    width: 100%;
    background: none;
    border: none;
    padding-bottom: 15px;
    align-items: flex-start;
}

.sidebar-submenu-controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.sidebar {
    margin-right: 20px;
}

/* Sidebar Buttons */
.sidebar-button-container {
    display: flex;
    width: 100%;
    background: none;
    border: none;
    padding: 15px;
    padding-left: var(--dl-space-space-twounits);
    align-items: flex-start;
}

    .sidebar-button-container:hover {
        background: var(--dl-color-gray-700) !important;
    }

.sidebar-category-button-container {
    display: flex;
    width: 100%;
    background: none;
    border: none;
    padding: 15px;
    padding-left: var(--dl-space-space-twounits);
    align-items: flex-start;
    justify-content: space-between;
}

.arrow-icon {
    align-self: center;
}

.arrow-icon-open {
    animation: spin 0.25s linear forwards;
}

.arrow-icon-closed {
    animation: unspin 0.25s linear forwards;
}

.arrow-icon-back {
    transform: rotate(180deg);
    margin-right: 15px;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(90deg);
    }
}

@keyframes unspin {
    from {
        transform: rotate(90deg);
    }

    to {
        transform: rotate(0deg);
    }
}

.sidebar-category-content-container {
    margin-left: 20px;
}

@media(max-width: 700px) {
    .sidebar {
        margin-right: 0px;
    }
}

@media(max-width: 479px) {
    .sidebar-button-container {
        padding-left: var(--dl-space-space-twounits);
    }

    .sidebar-category-button-container {
        padding-left: var(--dl-space-space-twounits);
    }
}
