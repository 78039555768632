.friend-list-container {
    display: flex;
    flex-direction: row;
    margin: 5px;
    justify-content: space-between;
}

.friend-list-item {
    display: flex;
    flex-direction: row;
}

.friend-list-item-profile-picture {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    object-fit: cover;
    border-radius: 50%;
}

.friend-list-item-name-container {
    display: flex;
    flex-direction: column;
}

.friend-list-item-primary-name {

}

.friend-list-item-secondary-name {
    font-size: 12px;
    color: var(--dl-color-gray-700)
}