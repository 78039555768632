.itineraries-list-container {
    flex: 0 0 auto;
    height: auto;
    display: flex;
    align-self: stretch;
    margin-top: 0px;
    align-items: flex-start;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    flex-direction: column;
}

.itineraries-list-header {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-self: flex-start;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    justify-content: space-between;
}

.itineraries-list-header-text {
    align-self: flex-start;
}

.itineraries-list-header-controls {
    flex: 0 0 auto;
    width: 60px;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.add-new-itinerary-button-container {
    align-self: center;
}

.itineraries-list-body {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    height: 600px;
    overflow: scroll;
}

.itinerary-list-item-container {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.itinerary-list-item-container1 {
    flex: 0 0 auto;
    width: auto;
    display: flex;
    position: relative;
    align-self: stretch;
    align-items: center;
    padding: var(--dl-space-space-unit);
    justify-content: flex-start;
}

/* this is jank, these icons just need to be in a div and properly aligned */
.itinerary-list-item-arrow-icon {
    top: 0px;
    right: var(--dl-space-space-twounits);
    width: 24px;
    bottom: 0px;
    height: 24px;
    margin: auto;
    position: absolute;
}

.itinerary-list-item-container2 {
    flex: 0 0 auto;
    width: 200px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.itinerary-list-item-name {
    font-size: 18px;
}

.itinerary-list-item-group-name {
    font-size: 12px;
}

.itineraries-list-add {
    position: absolute;
    width: 150%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    padding-top: 80px;
    padding-right: 40px;
    align-items: flex-start;
    z-index: 999;
    top: -15%;
    right: -25%;
}

.add-new-itinerary-popup-content {
    padding: 32px;
    background-color: #FFF;
    border-radius: 25px;
    z-index: 999;
    border-color: var(--dl-color-gray-700);
    border-width: 1px;
    border-style: solid;
    align-self: center;
}

.create-itinerary-popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    width: 400px;
}

.create-itinerary-popup-input {
    width: 260px;
    height: 35px;
}

.create-itinerary-popup-group-select {
    margin-top: var(--dl-space-space-unit);
    width: 260px;
    height: 35px;
}

.create-itinerary-popup-button {
    color: var(--dl-color-gray-white);
    font-size: 0.75rem;
    transition: 0.3s;
    margin-top: var(--dl-space-space-unit);
    background-color: var(--dl-color-gray-black);
}

    .create-itinerary-popup-button:hover {
        color: var(--dl-color-gray-white);
        background-color: var(--dl-color-gray-black);
    }

.create-itinerary-popup-new-group-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.itinerary-calendar-container {
    display: flex;
    flex-direction: column;
}

.itinerary-header-controls-container {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
}

.itinerary-settings-container {
    display: flex;
    flex-direction: column;
    width: 100px;
    align-items: center;
}

.create-itinerary-popup-new-group-members-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 75%;
    justify-content: center;
}

.add-itinerary-item-popup-input {
    width: 260px;
    height: 35px;
    margin-bottom: 7px;
}

.add-itinerary-item-popup-label {
    margin-top: 7px;
    margin-left: 70px;
    align-self: start;
    font-size: 12px;
}

.agenda-event {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.agenda-event-image {
    width: 200px;
    height: 200px;
}

.agenda-event-body-container {
    display: flex;
    flex-direction: column;
}

.itinerary-item-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    width: 400px;
}

.itinerary-item-card-image {
    width: 200px;
    height: 200px;
}

@media(max-width: 479px) {
    .rbc-calendar {
        width: 420px;
    }
}

@media(max-width: 440px) {
    .rbc-calendar {
        width: 400px;
    }
}

@media(max-width: 420px) {
    .rbc-calendar {
        width: 370px !important;
    }
}