/* Logo Container and Image */
.logo-logo {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.logo-image {
    width: auto;
    object-fit: cover;
}

/* Unused Classes */
.logo-twiyo {
    font-size: 25px;
    font-style: normal;
    font-family: "Merriweather";
    font-weight: 500;
}

.navigation-links-text {
    text-decoration: none;
    color: black;
}

/* Media Queries */

@media(max-width: 479px) {
    .logo-root-class-name {
        display: flex;
    }
}