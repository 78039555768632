.not-found-container1 {
    flex: 0 0 auto;
    height: var(--dl-size-size-maxwidth);
    display: flex;
    align-self: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.not-found-text2 {
    text-align: center;
}

/* Media Queries */
@media(max-width: 767px) {
    .not-found-container1 {
        width: 100%;
    }
}

@media(max-width: 479px) {
    .not-found-container1 {
        width: 100%;
        height: 100%;
    }
}
