/* Containers and Main Components */
.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    margin: 20px;
    width: 100%;
}

.paperplane {
    position: relative;
    overflow: hidden;
    width: 400px;
    height: 400px;
    background-color: #50befa;
    border-radius: 50%;
}

.plane {
    position: absolute;
    left: 200px;
    top: 150px;
    animation: move 3s linear infinite;
}

.clouds {
    position: absolute;
    top: 50px;
    left: 100px;
}

.loading-text {
    font-size: 20px;
    font-weight: 500;
    margin-top: 15px;
    text-align: center;
}

/* Plane Components */
.wingRight {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 22.5px solid transparent;
    border-right: 12.5px solid transparent;
    border-bottom: 115px solid white;
    transform: rotate(61deg);
    z-index: 4;
}

.wingLeft {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 25px solid transparent;
    border-bottom: 135px solid white;
    transform: rotate(78deg) skewY(-35deg);
    left: -18.5px;
    z-index: 3;
    top: -30px;
}

.bottom {
    position: relative;
    width: 0;
    height: 0;
    border-left: 12.5px solid transparent;
    border-right: 12.5px solid transparent;
    border-bottom: 25.5px solid #676d70;
    top: 71px;
    transform: rotate(-5deg);
    left: -52.5px;
    z-index: 3;
}

.top {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 30px solid #c1c7c9;
    top: 65px;
    transform: rotate(5deg);
    left: -60px;
    z-index: 1;
}

.middle {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 135px solid #c1c7c9;
    top: -13.5px;
    transform: rotate(72deg);
    left: -7.5px;
    z-index: 2;
}

/* Cloud Components */
.cloudOne:before, .cloudTwo:before, .cloudThree:before {
    content: "";
    position: absolute;
    width: 25px;
    height: 12.5px;
    background-color: #fff;
    border-radius: 100px 100px 0 0;
    left: -20px;
    top: 12.5px;
    box-shadow: 65px 0 #fff;
}

.cloudOne, .cloudTwo, .cloudThree {
    position: absolute;
    width: 50px;
    height: 25px;
    background-color: #fff;
    border-radius: 100px 100px 0 0;
}

.cloudOne {
    top: 0;
    left: 0;
    animation: cloud 3s linear infinite reverse;
}

.cloudTwo {
    top: 50px;
    left: 100px;
    animation: cloud 2.5s linear infinite reverse;
}

.cloudThree {
    top: 250px;
    left: 50px;
    animation: cloud 2.8s linear infinite reverse;
}

/* Animation Keyframes */
@keyframes move {
    0% {
        transform: translateY(0) rotateX(0);
    }

    25% {
        transform: translateY(-30px) rotateX(40deg);
    }

    50% {
        transform: rotateX(0) translateY(0);
    }

    75% {
        transform: rotateX(-40deg) translateY(30px);
    }

    100% {
        transform: rotateX(0) translateY(0);
    }
}

@keyframes cloud {
    from {
        left: -150px;
    }

    to {
        left: 400px;
    }
}

@media(max-width: 479px) {
    .loading-container {
        margin: 0;
        height: 90vh;
    }

    .paperplane {
        margin-top: 70px;
    }
}