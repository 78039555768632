/* About Page Container */
.about-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
}
/* About Hero Container and Components */
.about-hero {
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-threeunits);
    min-height: 80vh;
    align-items: center;
    flex-direction: column;
    background-size: cover;
    justify-content: center;
    background-image: url("https://images.unsplash.com/photo-1503149779833-1de50ebe5f8a?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDE2fHxzd2lzcyUyMGNoZWVzZSUyMHBsYW50fGVufDB8fHx8MTYyNTg0MTU0Mg&ixlib=rb-1.2.1&h=1200");
    background-position: center;
}

.about-text {
    color: var(--dl-color-gray-white);
    font-size: 0.75rem;
    letter-spacing: 2px;
    text-align:center;
}

.about-text01 {
    color: var(--dl-color-gray-white);
    font-size: 3rem;
    max-width: 450px;
    margin: var(--dl-space-space-twounits) 0;
    text-align: center;
}

.about-button {
    color: var(--dl-color-gray-white);
    transition: 0.3s;
    padding: var(--dl-space-space-unit) var(--dl-space-space-twounits);
    background-color: var(--dl-color-gray-black);
}

    .about-button:hover {
        transform: scale(1.02);
    }

/* About Steps Main Containers and Heading Components */
.about-steps {
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-threeunits);
    max-width: var(--dl-size-size-maxwidth);
    align-items: center;
    flex-direction: column;
}

.about-text04 {
    color: var(--dl-color-gray-black);
    font-size: 3rem;
}

.about-text05 {
    color: var(--dl-color-gray-700);
    font-size: 1.15rem;
    max-width: 600px;
    margin: var(--dl-space-space-unit) 0;
    text-align: center;
}

.about-steps-container {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
}

/* About Steps Individual Containers and Components */
.about-step {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.about-container03 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-left: 2px;
    padding-right: 2px;
    flex-direction: row;
    justify-content: center;
}

.about-line-transparent {
    flex: 1;
    height: 0px;
    display: flex;
    align-items: flex-start;
    border: 2px dashed transparent;
    flex-direction: row;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
}

.about-line-gray {
    flex: 1;
    height: 0px;
    display: flex;
    align-items: flex-start;
    border: 2px dashed var(--dl-color-gray-900);
    flex-direction: row;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
}

.about-container04 {
    flex: 0 0 auto;
    width: var(--dl-size-size-small);
    height: var(--dl-size-size-small);
    display: flex;
    align-items: center;
    border-radius: var(--dl-radius-radius-round);
    flex-direction: row;
    justify-content: center;
    background-color: #f5f5f5ff;
}

.about-icon {
    fill: var(--dl-color-gray-500);
    width: 24px;
    height: 24px;
}

.about-icon2 {
    width: 24px;
    height: 24px;
}

.about-container05 {
    display: flex;
    align-items: center;
    padding: var(--dl-space-space-unit) var(--dl-space-space-twounits);
    flex-direction: column;
}

.about-text08 {
    font-size: 1.5rem;
    text-align: center;
    font-weight: 500;
    margin-bottom: var(--dl-space-space-unit);
}

.about-text09 {
    color: var(--dl-color-gray-500);
    font-size: 0.75rem;
    text-align: center;
}

/* Media Queries */
@media(max-width: 991px) {
    .about-text04 {
        text-align: center;
    }

    .about-text05 {
        text-align: center;
    }
}

@media(max-width: 767px) {
    .about-hero {
        padding: 0 var(--dl-space-space-twounits);
    }

    .about-steps {
        padding: 0 var(--dl-space-space-twounits);
    }

    .about-steps-container {
        align-items: flex-start;
        padding-left: var(--dl-space-space-fiveunits);
        flex-direction: column;
    }

    .about-step {
        width: 100%;
        height: auto;
        flex-direction: row;
        justify-content: center;
    }

    .about-container03 {
        width: var(--dl-size-size-small);
        height: auto;
        align-self: stretch;
        padding: 0px 0px 2px;
        flex-direction: column;
        justify-content: space-between;
    }

    .about-line-transparent {
        border-top-width: 0px;
        border-left-width: 2px;
    }

    .about-line-gray {
        border-top-width: 0px;
        border-left-width: 2px;
    }

    .about-container05 {
        width: 100%;
        align-items: flex-start;
        padding: var(--dl-space-space-twounits) 0;
        flex-direction: column;
        justify-content: space-between;
    }

    .about-text08 {
        margin-top: var(--dl-space-space-twounits) 0px var(--dl-space-space-unit);
    }

    .about-text09 {
        text-align: left;
    }
}

@media(max-width: 479px) {
    .about-container01 {
        width: auto;
    }

    .about-hero {
        padding: var(--dl-space-space-twounits) var(--dl-space-space-unit);
    }

    .about-text {
        text-align: center;
    }

    .about-steps {
        padding: var(--dl-space-space-twounits) var(--dl-space-space-unit);
    }

    .about-steps-container {
        padding-left: 0px;
    }

    .about-container05 {
        padding: var(--dl-space-space-twounits) 0px var(--dl-space-space-twounits) var(--dl-space-space-unit);
    }

    .about-text08 {
        margin-left: 0px;
        margin-right: var(--dl-space-space-twounits);
    }
}
