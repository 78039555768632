.legal-container1 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.legal-body-text-container {

}

/* Legal Page Sidebar and Nav Containers */
.legal-sidebar {
    width: 300px;
    height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 35px;
}

.legal-nav {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.legal-body-title {
    text-align: center;
}

.legal-text {
    margin-bottom: var(--dl-space-space-threeunits);
}

/* Legal Nav Button Container */
.legal-nav-text-container {
    display:flex;
    width: 100%;
    background: none;
    border: none;
    padding: 15px;
    padding-left: var(--dl-space-space-twounits);
    align-items: flex-start;
}
    .legal-nav-text-container:hover {
        background: var(--dl-color-gray-700) !important;
    }


.legal-container2 {
    flex: 0 0 auto;
    width: 80%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}


@media(max-width: 767px) {
    .legal-sidebar {
        height: 70vh;
    }
}
