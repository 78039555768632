/* Votes Page Container */
.votes-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: stretch;
    flex-direction: column;
}

/* Votes Page Content Container */
.votes-container1 {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    max-width: 100%;
    max-height: 100%;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

/* Sidebar Container */
.votes-container2 {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    padding: var(--dl-space-space-twounits);
    position: relative;
    margin-top: var(--dl-space-space-threeunits);
    align-items: stretch;
}

/* VoteList and VotesBody Container */
.votes-container3 {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: flex-start;
}

/* VoteList Container and Main Component Containers */
.vote-list-container {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-oneandhalfunits);
    align-self: stretch;
    margin: 0px;
    align-items: flex-start;
    flex-direction: column;
}

.vote-list-header {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-self: flex-start;
    margin: 0px;
    justify-content: space-between;
}

.vote-list-body {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    height: 600px;
    overflow: scroll;
}

/* VoteList/ActiveVoteContent Popup Containers */
.vote-list-add {
    position: absolute;
    width: 150%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    padding-top: 80px;
    padding-right: 40px;
    align-items: flex-start;
    z-index: 999;
    top: -15%;
    right: -25%;
}

.add-new-vote-popup-content {
    padding: 32px;
    background-color: #FFF;
    border-radius: 25px;
    z-index: 999;
    border: 1px solid var(--dl-color-gray-700);
    align-self: center;
}

/* VoteList Header Components */
.vote-list-header-text {
    align-self: flex-start;
}

.vote-list-header-controls {
    flex: 0 0 auto;
    width: 60px;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.vote-list-controls-filter-icon {
    width: 24px;
    height: 24px;
    align-self: center;
}
    .vote-list-controls-filter-icon:hover {
        cursor: pointer;
        transform: scale(1.1);
    }

.add-new-vote-button-container {
    align-self: center;
}

.vote-list-controls-add-icon {
    width: 24px;
    height: 24px;
    align-self: center;
}
    .vote-list-controls-add-icon:hover {
        cursor: pointer;
        transform: scale(1.1);
    }

/* VoteListItem Containers and Components */
.vote-list-item-container {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.vote-list-item-container1 {
    flex: 0 0 auto;
    width: auto;
    display: flex;
    position: relative;
    align-self: stretch;
    align-items: center;
    padding: var(--dl-space-space-unit);
    justify-content: flex-start;
}

.vote-list-item-container2 {
    flex: 0 0 auto;
    width: 200px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.vote-list-item-name {
    font-size: 18px;
}

.vote-list-item-group-name {
    font-size: 12px;
}

.vote-list-item-arrow-icon {
    top: 0px;
    right: var(--dl-space-space-twounits);
    width: 24px;
    bottom: 0px;
    height: 24px;
    margin: auto;
    position: absolute;
}

/* CreateVotePopupContent/CreateVotingOptionPopupContent Containers and Components */
.create-vote-popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    width: 400px;
}

.create-vote-popup-input {
    width: 260px;
    height: 35px;
    cursor: text;
}

.create-vote-popup-input-label {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.create-vote-popup-group-select {
    width: 260px;
    height: 35px;
    cursor: pointer;
}

.create-vote-popup-new-group-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.create-vote-popup-new-group-members-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 75%;
    justify-content: center;
}

.create-vote-popup-button {
    color: var(--dl-color-gray-white);
    font-size: 0.75rem;
    transition: 0.3s;
    margin-top: var(--dl-space-space-unit);
    background-color: var(--dl-color-gray-black);
}

    .create-vote-popup-button:hover {
        color: var(--dl-color-gray-white);
        background-color: var(--dl-color-gray-black);
    }

/* VotesHeader Containers and Components */
.vote-poll-tools {
    flex: 0 0 auto;
    width: 100%;
    height: 10%;
    display: flex;
    padding: Unit;
    align-items: flex-start;
}

.vote-poll-name-container {
    flex: 0 0 auto;
    width: 75%;
    height: auto;
    display: flex;
    align-self: center;
    align-items: flex-start;
}

.edit-poll-name-icon {
    width: 24px;
    height: 24px;
    align-self: center;
    margin-left: var(--dl-space-space-unit)
}

.vote-poll-tools-container {
    flex: 0 0 auto;
    width: 25%;
    height: auto;
    display: flex;
    align-self: center;
    align-items: flex-start;
    align-content: center;
    justify-content: flex-end;
}

.vote-add-poll-option {
    width: 24px;
    height: 24px;
    display: none;
}

.vote-poll-settings {
    width: 24px;
    height: 24px;
}
    .vote-poll-settings:hover {
        cursor: pointer;
        transform: scale(1.1);
    }

.edit-poll-name-textarea {
    flex: 1;
    width: 100%;
    resize: none;
    height: 50px;
    font-size: 20px;
}

/* VotesBody/StartVotePage/VoteNotStartedPage/NoPollSelectedContent Container */
.vote-poll-content {
    flex: 0 0 auto;
    width: 70%;
    height: auto;
    display: flex;
    align-self: stretch;
    align-items: flex-start;
    flex-direction: column;
}

/* ActiveVoteContent Containers and Components */
.vote-poll-options {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-self: flex-start;
    align-items: flex-start;
    padding-top: var(--dl-space-space-oneandhalfunits);
    justify-content: center;
}

.vote-add-poll-option1 {
    fill: var(--dl-color-gray-500);
    width: 75px;
    height: 75px;
    align-self: center;
    margin-left: var(--dl-space-space-oneandhalfunits);
}

.vote-poll-decision-container {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.vote-submit {
    flex: 0 0 auto;
    width: 40%;
    height: 50px;
    margin: var(--dl-space-space-unit);
    display: flex;
    align-self: center;
    align-items: flex-start;
    border-radius: 16px;
    justify-content: center;
    background-color: var(--dl-color-twiyo-green);
}
    .vote-submit .disabled {
        background-color: #e5e5e5;
    }

.vote-submit-text {
    fill: var(--dl-color-gray-900);
    color: var(--dl-color-gray-900);
    align-self: center;
}

.vote-abstain {
    flex: 0 0 auto;
    width: 40%;
    height: 50px;
    margin: var(--dl-space-space-unit);
    display: flex;
    align-self: center;
    align-items: flex-start;
    border-radius: 16px;
    justify-content: center;
    background-color: var(--dl-color-gray-900);
}

.vote-abstain-text {
    align-self: center;
}

/* VoteOptionCard Containers and Components */
.vote-poll-option {
    flex: 0 0 auto;
    margin: var(--dl-space-space-unit);
    display: flex;
    padding: var(--dl-space-space-oneandhalfunits);
    align-items: flex-start;
    border-radius: 16px;
    flex-direction: column;
    background-color: var(--dl-color-gray-900);
}

.vote-poll-option-selected {
    flex: 0 0 auto;
    margin: var(--dl-space-space-unit);
    display: flex;
    padding: var(--dl-space-space-oneandhalfunits);
    align-items: flex-start;
    border: 2px solid var(--dl-color-gray-black);
    border-radius: 16px;
    flex-direction: column;
    background-color: var(--dl-color-gray-900);
}

.vote-image {
    width: 200px;
    object-fit: cover;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

/* PollResultsContent Containers and Components */
.vote-results-poll-results-container {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-self: flex-start;
    align-items: flex-start;
    padding-top: var(--dl-space-space-oneandhalfunits);
    flex-direction: row;
    justify-content: flex-start;
}

.vote-results-poll-winner {
    flex: 0 0 auto;
    width: 30%;
    display: flex;
    align-self: flex-start;
    align-items: flex-start;
    padding-top: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: center;
    margin-right: 100px;
}

.vote-results-text1 {
    align-self: center;
}

.vote-results-poll-option {
    flex: 0 0 auto;
    margin: var(--dl-space-space-unit);
    display: flex;
    padding: var(--dl-space-space-oneandhalfunits);
    align-self: center;
    align-items: flex-start;
    border-radius: 16px;
    flex-direction: column;
    background-color: var(--dl-color-gray-900);
}

.vote-results-image {
    width: 200px;
    object-fit: cover;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.vote-results-vote-distributions {
    flex: 0 0 auto;
    width: 70%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.vote-results-poll-decision-container {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.vote-results-recast {
    flex: 0 0 auto;
    width: 40%;
    height: 50px;
    margin: var(--dl-space-space-unit);
    display: flex;
    align-self: center;
    align-items: flex-start;
    border-radius: 16px;
    justify-content: center;
    background-color: var(--dl-color-primary-300);
}

.vote-results-recast-text {
    fill: var(--dl-color-gray-900);
    color: var(--dl-color-gray-900);
    align-self: center;
    font-size: 20px;
    margin-bottom: 0px;
}

/* VoteResultsListItem */
.vote-results-vote-results-wrapper2 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
}

.vote-results-poll-option3 {
    flex: 0 0 auto;
    margin: var(--dl-space-space-unit);
    display: flex;
    padding: var(--dl-space-space-oneandhalfunits);
    align-self: flex-start;
    align-items: flex-start;
    border-radius: 16px;
    flex-direction: column;
    background-color: var(--dl-color-gray-900);
    width: 200px;
}

.vote-results-vote-results-container2 {
    flex: 0 0 auto;
    width: 70%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.vote-results-percentage-of-vote2 {
    flex: 0 0 auto;
    width: 100%;
    height: 40%;
    display: flex;
    align-items: center;
}

.vote-results-text4 {
    font-size: 20px;
    align-self: flex-end;
    font-style: normal;
    font-weight: 500;
}

.vote-results-vote-tally-container2 {
    flex: 0 0 auto;
    width: 100%;
    height: 60%;
    display: flex;
    align-items: flex-start;
}

.vote-results-vote-tally4 {
    flex: 0 0 auto;
    border: 2px dashed rgba(120, 120, 120, 0.4);
    height: 50%;
    display: flex;
    align-self: center;
    align-items: flex-start;
    background-color: var(--dl-color-success-500);
}

.vote-results-vote-tally5 {
    flex: 0 0 auto;
    border: 2px dashed rgba(120, 120, 120, 0.4);
    height: 50%;
    display: flex;
    align-self: center;
    align-items: flex-start;
    background-color: var(--dl-color-gray-700);
}

/* NewGroupMemberName Container and Components */
.new-group-member-name-container {
    height: 20px;
    margin: 5px;
    border-radius: 25px;
    width: auto;
    display: flex;
    position: relative;
    align-items: center;
    border: solid;
    border-width: 1px;
}

.new-group-member-name {
    font-size: 13px;
    align-self: center;
    font-style: normal;
    font-weight: 500;
    margin-top: 18px var(--dl-space-space-unit) 0;
}

.new-group-member-name-delete-icon {
    width: 15px;
    height: 15px;
    align-self: center;
    margin-right: var(--dl-space-space-unit);
}
    .new-group-member-name-delete-icon:hover {
        filter: invert(49%) sepia(14%) saturate(7015%) hue-rotate(330deg) brightness(100%) contrast(100%);
        cursor: pointer;
    }

/* Media Queries */
@media(max-width: 1600px) {
    .vote-container {
        flex-direction: column;
    }

    .vote-container1 {
        width: 100%;
        justify-content: space-between;
    }

    .vote-container3 {
        flex: 1;
        align-items: stretch;
    }

    .vote-polls-container {
        width: 20%;
        height: auto;
        align-self: stretch;
    }

    .vote-poll {
        width: auto;
        padding: var(--dl-space-space-unit);
        position: relative;
        align-self: stretch;
        align-items: center;
    }

    .vote-arrow {
        top: 0px;
        right: 0px;
        bottom: 0px;
        margin: auto;
        position: absolute;
    }

    .vote-poll-details {
        align-items: flex-start;
        justify-content: flex-start;
    }

    .vote-poll-content {
        width: 70%;
        height: auto;
        align-self: stretch;
    }

    .vote-poll-tools {
        height: auto;
        padding: var(--dl-space-space-unit);
    }

    .vote-poll-name-container {
        width: 75%;
        height: auto;
        align-self: center;
    }

    .vote-poll-name1 {
        align-self: center;
        font-style: normal;
        font-weight: 400;
        width: 700px;
    }

    .vote-poll-tools-container {
        width: 25%;
        height: auto;
        align-self: center;
    }

    .vote-results-container {
        flex-direction: column;
    }

    .vote-results-container1 {
        width: 100%;
        justify-content: space-between;
    }

    .vote-results-container3 {
        flex: 1;
        align-items: stretch;
    }

    .vote-results-polls-container {
        width: 20%;
        height: auto;
        align-self: stretch;
    }

    .vote-results-poll {
        width: auto;
        padding: var(--dl-space-space-unit);
        position: relative;
        align-self: stretch;
        align-items: center;
    }

    .vote-results-arrow {
        top: 0px;
        right: 0px;
        bottom: 0px;
        margin: auto;
        position: absolute;
    }

    .vote-results-poll-details {
        align-items: flex-start;
        justify-content: flex-start;
    }

    .vote-results-poll-content {
        width: 80%;
        height: auto;
        align-self: stretch;
    }

    .vote-results-poll-tools {
        height: auto;
        padding: var(--dl-space-space-unit);
    }

    .vote-results-poll-name-container {
        width: 75%;
        height: auto;
        align-self: center;
    }

    .vote-results-poll-name1 {
        align-self: center;
        font-style: normal;
        font-weight: 400;
    }

    .vote-results-poll-tools-container {
        width: 25%;
        height: auto;
        align-self: center;
    }
}

@media(max-width: 1200px) {
    .vote-polls-container {
        width: 40%;
    }

    .vote-poll-content {
        width: 60%;
    }

    .vote-results-polls-container {
        width: 40%;
    }

    .vote-results-poll-content {
        width: 60%;
    }

    .vote-results-poll-results-container {
        flex-direction: column;
    }

    .vote-results-poll-winner {
        align-self: center;
    }

    .vote-results-text1 {
        text-align: center;
    }
}

@media(max-width: 991px) {
    .vote-add-poll-option {
        display: inherit;
    }

    .vote-poll-settings {
        margin-left: var(--dl-space-space-halfunit);
    }

    .vote-add-poll-option1 {
        display: none;
    }

    .vote-results-poll-settings {
        margin-left: var(--dl-space-space-halfunit);
    }
}

@media(max-width: 767px) {
    .vote-container3 {
        justify-content: center;
    }

    .vote-polls-container {
        display: none;
    }

    .vote-results-container3 {
        justify-content: center;
    }

    .vote-results-polls-container {
        display: none;
    }
}

@media(max-width: 479px) {
    .vote-polls-container {
        display: none;
    }

    .vote-poll-content {
        width: 100%;
    }

    .vote-poll-options {
        align-items: center;
        flex-direction: column;
    }

    .vote-results-polls-container {
        display: none;
    }

    .vote-results-poll-content {
        width: 100%;
    }

    .vote-results-poll-results-container {
        align-items: center;
        flex-direction: column;
    }

    .vote-results-poll-winner {
        align-items: center;
        flex-direction: column;
    }

    .vote-results-vote-results-wrapper {
        height: 220px;
        align-self: center;
        align-items: center;
        flex-direction: column;
    }

    .vote-results-poll-option1 {
        align-self: center;
        justify-content: flex-start;
    }

    .vote-results-vote-results-container {
        height: auto;
        align-self: center;
    }

    .vote-results-percentage-of-vote {
        height: auto;
    }

    .vote-results-vote-tally-container {
        height: 50px;
    }

    .vote-results-vote-results-wrapper1 {
        height: 220px;
        align-self: center;
        align-items: center;
        flex-direction: column;
    }

    .vote-results-poll-option2 {
        align-self: center;
        justify-content: flex-start;
    }

    .vote-results-vote-results-container1 {
        height: auto;
        align-self: center;
    }

    .vote-results-percentage-of-vote1 {
        height: auto;
    }

    .vote-results-vote-tally-container1 {
        height: 50px;
    }

    .vote-results-vote-results-wrapper2 {
        height: 220px;
        align-self: center;
        align-items: center;
        flex-direction: column;
    }

    .vote-results-poll-option3 {
        align-self: center;
        justify-content: flex-start;
    }

    .vote-results-vote-results-container2 {
        height: auto;
        align-self: center;
    }

    .vote-results-percentage-of-vote2 {
        height: auto;
    }

    .vote-results-text4 {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
    }

    .vote-results-vote-tally-container2 {
        height: 50px;
    }

    .vote-results-vote-tally4 {
        width: 20%;
    }

    .vote-results-vote-tally5 {
        width: 80%;
    }
}

/* Unused Classes */
.add-vote-item-popup-input {
    width: 260px;
    height: 35px;
    margin-bottom: 7px;
}

.add-vote-item-popup-label {
    margin-top: 7px;
    margin-left: 70px;
    align-self: start;
    font-size: 12px;
}

.vote-image1 {
    width: 200px;
    margin-top: 0px;
    object-fit: cover;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.vote-icon6 {
    width: 24px;
    height: 24px;
}

.vote-results-vote-results-wrapper {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
}

.vote-results-poll-option1 {
    flex: 0 0 auto;
    margin: var(--dl-space-space-unit);
    display: flex;
    padding: var(--dl-space-space-oneandhalfunits);
    align-self: flex-start;
    align-items: flex-start;
    border-radius: 16px;
    flex-direction: column;
    background-color: var(--dl-color-gray-900);
}

.vote-results-vote-results-container {
    flex: 0 0 auto;
    width: 70%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.vote-results-percentage-of-vote {
    flex: 0 0 auto;
    width: 100%;
    height: 40%;
    display: flex;
    align-items: center;
}

.vote-results-text2 {
    font-size: 20px;
    align-self: flex-end;
    font-style: normal;
    font-weight: 500;
}

.vote-results-vote-tally-container {
    flex: 0 0 auto;
    width: 100%;
    height: 60%;
    display: flex;
    align-items: flex-start;
}

.vote-results-vote-tally {
    flex: 0 0 auto;
    width: 40%;
    border: 2px dashed rgba(120, 120, 120, 0.4);
    height: 50%;
    display: flex;
    align-self: center;
    align-items: flex-start;
    background-color: var(--dl-color-success-500);
}

.vote-results-vote-tally1 {
    flex: 0 0 auto;
    width: 60%;
    border: 2px dashed rgba(120, 120, 120, 0.4);
    height: 50%;
    display: flex;
    align-self: center;
    align-items: flex-start;
    background-color: var(--dl-color-gray-700);
}

.vote-results-vote-results-wrapper1 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
}

.vote-results-poll-option2 {
    flex: 0 0 auto;
    margin: var(--dl-space-space-unit);
    display: flex;
    padding: var(--dl-space-space-oneandhalfunits);
    align-self: flex-start;
    align-items: flex-start;
    border-radius: 16px;
    flex-direction: column;
    background-color: var(--dl-color-gray-900);
}

.vote-results-vote-results-container1 {
    flex: 0 0 auto;
    width: 70%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.vote-results-percentage-of-vote1 {
    flex: 0 0 auto;
    width: 100%;
    height: 40%;
    display: flex;
    align-items: center;
}

.vote-results-text3 {
    font-size: 20px;
    align-self: flex-end;
    font-style: normal;
    font-weight: 500;
}

.vote-results-vote-tally-container1 {
    flex: 0 0 auto;
    width: 100%;
    height: 60%;
    display: flex;
    align-items: flex-start;
}

.vote-results-vote-tally2 {
    flex: 0 0 auto;
    width: 40%;
    border: 2px dashed rgba(120, 120, 120, 0.4);
    height: 50%;
    display: flex;
    align-self: center;
    align-items: flex-start;
    background-color: var(--dl-color-success-500);
}

.vote-results-vote-tally3 {
    flex: 0 0 auto;
    width: 60%;
    border: 2px dashed rgba(120, 120, 120, 0.4);
    height: 50%;
    display: flex;
    align-self: center;
    align-items: flex-start;
    background-color: var(--dl-color-gray-700);
}

.vote-results-icon4 {
    width: 24px;
    height: 24px;
}

/*vote attribute crap*/
.vote-attribute-edit-container {
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
}

.vote-attribute-container {

}

.vote-attribute-upsert-container {
    display: flex;
    flex-direction: row;
}

.vote-attribute-upsert-container-controls {
    display: flex;
    flex-direction: row;
}

.vote-attribute-input {
    width: 100px;
}

.vote-attribute-upsert-container-controls-button {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    background-color: unset;
    margin-right: 10px;
    padding: unset;
    padding-block: unset;
    padding-inline: unset;
    width: 40px;
    border: none;
}

    .vote-attribute-upsert-container-controls-button:hover {
        background-color: var(--dl-color-gray-700);
    }

/*options*/
.reset-votes-button {
    background-color: var(--dl-color-danger-700) !important;
    color: var(--dl-color-gray-white) !important;
    border-color: unset !important;
    margin-top: 10px;
}

.change-poll-name-container {
    display: flex;
    flex-direction: row;
    width: 325px;
    margin-bottom: 25px;
}

.poll-options-property-label {
    font-size: 18px;
    width: fit-content;
    margin-right: 5px;
}

.poll-options-property-value {
    font-size: 18px;
    margin-right: 5px;
}

.poll-options-property-controls {
    display: flex;
    flex-direction: row;
}

/*voting option stuff, i'm sure this is somewhere else too*/
.voting-option-edit-icon {
    right: -230px;
    top: 50px;
    position: relative;
}

.voting-option-image {
    width: 200px;
    height: 200px;
}

.complete-poll-button {
    margin-top: 10px;
}