/* Surrounding Container for Popup */
.popup {
    position: fixed;
    width: 150%;
    height: 1500px;
    display: flex;
    justify-content: center;
    align-content: center;
    padding-top: 80px;
    padding-right: 40px;
    align-items: flex-start;
    z-index: 999;
    top: -15%;
    right: -25%;
}

/* Popup Container and Internal Components */
.popup-content {
    position: relative;
    padding: 32px;
    height: 375px;
    background-color: #FFF;
    border-radius: 25px;
    z-index: 999;
    border: 1px solid var(--dl-color-gray-700);
    top: 12%;
}

.close-button {
    position: relative;
    display: flex;
    justify-content: right;
}

.close-button-icon {
    position: relative;
    top: -10px;
    right: -20px;
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
}

    .close-button-icon:hover {
        filter: invert(49%) sepia(14%) saturate(7015%) hue-rotate(330deg) brightness(100%) contrast(100%);
        cursor: pointer;
    }