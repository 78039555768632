.extra-small {
    width: 14px;
    height: 14px;
}

.extra-small-1 {
    width: 16px;
    height: 16px;
}

.extra-small-2 {
    width: 18px;
    height: 18px;
}

.extra-small-3 {
    width: 20px;
    height: 20px;
}

.small {
    width: 24px;
    height: 24px;
}

.medium {
    width: 38px;
    height: 38px;
}

.align-self-center {
    align-self: center;
}

.reverse {
    transform: rotate(180deg);
}
