/* Notification Icon */
.account-icon {
    border-radius: 50%;
    width: 60%;
    height: 60%;
}

.notification-icon-button {
    border: none;
    background-color: transparent;
    margin-left: var(--dl-space-space-twounits);
    width: 50%;
    height: 50%;
}

/* Notification Popup Content */
.notification-popup-style {
    position: absolute;
    width: 150%;
    height: 1500px;
    display: flex;
    justify-content: center;
    align-content: center;
    padding-top: 80px;
    padding-right: 40px;
    align-items: flex-start;
    z-index: 999;
    top: -15%;
    right: -25%;
}

.notification-popup-content-style {
    position: relative;
    padding: 32px;
    background-color: #FFF;
    border-radius: 25px;
    z-index: 999;
    border: 1px solid var(--dl-color-gray-700);
    right: inherit;
    top: 20px;
}

.notification-popup-content-container {
    flex: auto;
    display: flex;
    flex-direction: column;
}

.notification-popup-content-header {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
}

.notification-popup-content-controls {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    align-content: center;
    flex-wrap: wrap;
    margin-right: 5px;
    margin-left: 15px;
}

.notification-popup-content-body {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
}

/* Popup Item */
.notification-popup-item {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 7px;
    width: 375px;
}

.notification-popup-item-controls {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    width: 20%;
    justify-content: flex-end;
}

.notification-message {
    font-size: 15px;
    margin-right: 10px;
    width: 80%;
}

.popup-item-button {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    background-color: white;
    border-color: var(--dl-color-gray-700);
    border-width: 1px;
    border-style: solid;
    padding-block: unset;
    padding-inline: unset;
    padding: 4px;
    border-radius: 9px;
    font-size: 15px;
    margin-left: 5px;
}

    .popup-item-button:hover {
        background-color: var(--dl-color-gray-700);
    }

.notification-dashboard-content-container {
    flex: auto;
    display: flex;
    flex-direction: column;
    margin-left: 40px;
    max-width: 250px;
}

@media(max-width: 479px) {
    .notification-icon-button {
        margin-left: 0;
    }

    .notification-popup-content-style {
        top: 150px;
        right: -30px;
    }

    .notification-popup-style {
        width: 100%;
        right: 1px;
    }

    .notification-dashboard-content-container {
        margin-left: 0px;
        margin-top: 40px;
    }
}

