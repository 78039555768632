.suggestion-results {
    position: absolute;
    z-index: 999;
    width: 184px;
    background-color: white;
    border: 1px solid var(--dl-color-gray-700);
    margin-top: 30px;
}

.suggestion-result-item-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: white;
    border: unset;
    width: 100%;
}
    .suggestion-result-item-container:hover {
        background-color: var(--dl-color-gray-700);
    }

.add-trip-member-input-section {
    display: flex;
    flex-direction: column;
}

.suggestion-result-item-invite-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.find-user-control-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}