/* Itinerary Page Container */
.itineraries-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: stretch;
    flex-direction: column;
}

/* Itenerary Pager Content Container */
.itineraries-container1 {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    max-width: 100%;
    max-height: 100%;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

/* Sidebar Container */
.itineraries-container2 {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    padding: var(--dl-space-space-twounits);
    position: relative;
    margin-top: var(--dl-space-space-threeunits);
    align-items: stretch;
}

/* ItineraryList and ItineraryCalendar Container */
.itineraries-container3 {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: flex-start;
}
