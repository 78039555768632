.chat-group-focused-container3 {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: flex-start;
}

.chat-group-focused-channels-container {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-self: stretch;
    margin-top: 0px;
    align-items: flex-start;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    flex-direction: column;
}

.chat-group-focused-text {
    align-self: flex-start;
}

.chat-group-focused-channel-list-controls {
    flex: 0 0 auto;
    width: 60px;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.chat-group-focused-icon {
    width: 24px;
    height: 24px;
    align-self: center;
}

.chat-group-focused-icon02 {
    width: 24px;
    height: 24px;
    align-self: center;
}

.chat-group-focused-channels {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 600px;
    overflow: scroll;
}

.channel-item-name-content {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    align-items: flex-start;
}

.chat-group-focused-chat {
    flex: 0 0 auto;
    width: auto;
    display: flex;
    position: relative;
    align-self: stretch;
    align-items: center;
    padding: var(--dl-space-space-unit);
    justify-content: flex-start;
}

/* This is jank, this icon should be in a div and properly aligned */
.chat-group-focused-icon04 {
    top: 0px;
    right: var(--dl-space-space-twounits);
    bottom: 0px;
    margin: auto;
    position: absolute;
}

.chat-group-focused-container4 {
    flex: 0 0 auto;
    width: 200px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.chat-group-focused-text1 {
    font-size: 18px;
}

.chat-group-focused-chats {
    flex: 0 0 auto;
    width: 80%;
    height: auto;
    display: flex;
    align-self: stretch;
    align-items: flex-start;
    flex-direction: column;
}

.chat-group-focused-channel-tools {
    flex: 0 0 auto;
    width: 100%;
    height: 10%;
    display: flex;
    padding: var(--dl-space-space-unit);
    align-items: flex-start;
}

.chat-group-focused-group-name-container {
    flex: 0 0 auto;
    width: 75%;
    height: auto;
    display: flex;
    align-self: center;
    align-items: flex-start;
}

.chat-group-focused-group-tools-container {
    flex: 0 0 auto;
    width: 25%;
    height: auto;
    display: flex;
    align-self: center;
    align-items: flex-start;
    align-content: center;
    justify-content: flex-end;
}

.chat-group-focused-messages {
    flex: 0 0 auto;
    width: 100%;
    height: 600px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    overflow: scroll;
}

.chat-group-focused-message-sent {
    flex: 0 0 auto;
    width: auto;
    border: 1px;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    max-width: 50%;
    min-width: 200px;
    align-self: flex-end;
    max-height: 80%;
    align-items: flex-start;
    border-color: var(--dl-color-gray-black);
    border-radius: 16px;
    flex-direction: column;
}

.chat-group-focused-container5 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-halfunit);
}

.chat-group-focused-text4 {
    font-size: 14px;
}

.chat-group-focused-message-received {
    flex: 0 0 auto;
    width: auto;
    border: 1px;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    max-width: 50%;
    min-width: 200px;
    align-self: flex-start;
    max-height: 80%;
    align-items: flex-start;
    border-color: var(--dl-color-gray-black);
    border-radius: 16px;
    flex-direction: column;
}

.chat-group-focused-container6 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-halfunit);
}

.chat-group-focused-text5 {
    font-size: 14px;
    margin-right: var(--dl-space-space-unit);
}

.chat-group-focused-text6 {
    font-size: 14px;
}

.chat-group-focused-message-tools {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.chat-group-focused-textarea {
    flex: 1;
    width: 100%;
    resize: none;
}

.chat-group-focused-container7 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-halfunit);
    justify-content: space-between;
}

.chat-group-focused-text-tools {
    flex: 0 0 auto;
    width: 200px;
    height: auto;
    display: flex;
    align-items: flex-start;
    margin-left: var(--dl-space-space-oneandhalfunits);
    justify-content: space-between;
}

.chat-group-focused-send-button-container {
    flex: 0 0 auto;
    width: auto;
    height: auto;
    display: flex;
    align-items: flex-start;
    margin-right: var(--dl-space-space-twounits);
    justify-content: flex-start;
}

.single-line-height {
    height: 70px;
}

.link-preview {
    display: flex;
    flex-direction: column;
}

.link-preview-image {
    width: 200px;
    height: 200px;
    display: block;
    object-fit: cover;
}

.link-preview-title {
    font-size: 14px;
}

.link-preview-description {
    font-size: 10px;
}

.chat-group-focused-edit-group-name {
    width: 24px;
    height: 24px;
    align-self: center;
    margin-left: var(--dl-space-space-unit)
}

.chat-group-focused-add-new-chat-container {
    align-self: center;
}

.chat-group-focused-edit-group-name-textarea {
    flex: 1;
    width: 100%;
    resize: none;
    height: 50px;
    font-size: 20px;
}

.chat-added-image {
    width: 50px;
    height: 50px;
}

.chat-user-list-item {
    display: flex;
    flex-direction: row;
}

.chat-user-list-profile-picture {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    object-fit: cover;
    border-radius: 50%;
}

.chat-user-list-profile-name-container {
    display: flex;
    flex-direction: column;
}

.chat-user-list-profile-display-name {

}

.chat-user-list-profile-second-name {
    font-size: 12px;
    color: var(--dl-color-gray-700)
}

.add-chat-member-popup-content {
    display: flex;
    flex-direction: column;
}

.add-chat-member-popup-label {
    margin-bottom: 5px;
}

.add-chat-member-input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.add-chat-member-check-icon {
    margin-left: 5px;
}

.add-chat-member-no-user-found-text {
    font-size: 12px;
    color: var(--dl-color-gray-700);
    overflow-wrap: normal;
    width: 200px;
    margin-bottom: 5px;
}

.add-chat-member-no-user-found-container {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.add-chat-member-no-user-found-invite-button {
    width: 120px;
    height: 30px;
    padding: 1px;
}

.add-chat-member-send-invite-button-icon {
    position: relative;
    top: -3px;
}

.add-chat-member-input-errors {
    font-size: 12px;
    overflow-wrap: normal;
    color: var(--dl-color-danger-700)
}

.chat-user-list-item-container {
    display: flex;
    flex-direction: row;
    margin: 5px;
    justify-content: space-between;
}

.messages-panel-popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    width: 400px;
}

.trip-channel-with-sub-channels-container {
    display: flex;
    flex-direction: column;
}

.trip-add-event-input {
    width: 260px;
    height: 35px;
    margin-bottom: 7px;
}

.trip-add-event-label {
    margin-top: 7px;
    margin-left: 70px;
    align-self: start;
    font-size: 12px;
}

.trip-event-like-unlike-container {
    align-self: flex-end;
}

.chat-reaction-button {
    margin: 3px;
}

.chat-reaction-button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.chat-type-separator {
    overflow: hidden;
    text-align: center;
    color: var(--dl-color-gray-500);
    margin-top: 15px;
    margin-bottom: 5px;
}

.chat-type-separator::before,
.chat-type-separator::after {
    background-color: var(--dl-color-gray-500);
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 20%;
}

.chat-type-separator::before {
    right: 0.5em;
    margin-left: -50%;
}

.chat-type-separator::after {
    left: 0.5em;
    margin-right: -50%;
}

.member-added-message {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.new-chat-members-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
}

@media(max-width: 1600px) {
    .chat-group-focused-container {
        flex-direction: column;
    }

    .chat-group-focused-container1 {
        width: 100%;
        justify-content: space-between;
    }

    .chat-group-focused-container3 {
        flex: 1;
        align-items: stretch;
    }

    .chat-group-focused-channels-container {
        height: auto;
        align-self: stretch;
        padding: var(--dl-space-space-unit)
    }

    .chat-group-focused-chat {
        width: auto;
        padding: var(--dl-space-space-unit);
        position: relative;
        align-self: stretch;
        align-items: center;
    }

    .chat-group-focused-icon04 {
        top: 0px;
        right: 0px;
        bottom: 0px;
        margin: auto;
        position: absolute;
    }

    .chat-group-focused-container4 {
        align-items: flex-start;
        justify-content: flex-start;
    }

    .chat-group-focused-chats {
        width: 75%;
        height: auto;
        align-self: stretch;
    }

    .chat-group-focused-channel-tools {
        height: auto;
        padding: var(--dl-space-space-unit);
    }

    .chat-group-focused-group-name-container {
        width: 75%;
        height: auto;
        align-self: center;
    }

    .chat-group-focused-text3 {
        align-self: center;
        font-style: normal;
        font-weight: 400;
    }

    .chat-group-focused-group-tools-container {
        width: 25%;
        height: auto;
        align-self: center;
    }

    .chat-group-focused-messages {
        width: 100%;
    }

    .chat-group-focused-message-sent {
        width: auto;
        padding: var(--dl-space-space-halfunit);
        max-width: 80%;
        min-width: 200px;
        align-self: flex-end;
        border-color: var(--dl-color-gray-black);
        border-width: 1px;
        border-radius: 16px;
    }

    .chat-group-focused-container5 {
        height: auto;
        margin-bottom: var(--dl-space-space-halfunit);
    }

    .chat-group-focused-text4 {
        font-size: 14px;
    }

    .chat-group-focused-message-received {
        width: auto;
        padding: var(--dl-space-space-halfunit);
        max-width: 80%;
        min-width: 200px;
        border-color: var(--dl-color-gray-black);
        border-width: 1px;
        border-radius: 16px;
    }

    .chat-group-focused-container6 {
        height: auto;
        margin-bottom: var(--dl-space-space-halfunit);
    }

    .chat-group-focused-text5 {
        font-size: 14px;
    }

    .chat-group-focused-text6 {
        font-size: 14px;
    }
}

@media(max-width: 1200px) {
    .chat-group-focused-chats {
        width: 60%;
    }
}

@media(max-width: 991px) {
    .chat-group-focused-container2 {
        margin-top: 0px;
    }

    .chat-group-focused-chats {
        width: 100%;
    }
}
